<template lang="pug">
#connecting-to-agent.interrupt-step-component
  #spinner

  h2.connecting Connecting...
  p Someone will be with you shortly.

  button.button.action-btn.end-call(@click="endCallRequest") End Call
</template>

<script>
import LottieAnimation from 'lottie-web-vue'
import AmplitudeAPI from '@/utils/amplitude'
import MixpanelAPI from '@/utils/mixpanel'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  props: {
    step: { type: String },
    checkReconnect: { type: Function },
    getMediaTracks: { type: Function },
    disableLocalTracks: { type: Function },
  },

  data() {
    return {
      stepTimeStamp: Date.now(),
    }
  },

  computed: {
    ...mapState({
      localVideoTrack: state => state.twilio.localVideoTrack,
      localAudioTrack: state => state.twilio.localAudioTrack,
      localDataTrack: state => state.twilio.localDataTrack,
      proceededWithoutVideo: state => state.user.proceededWithoutVideo,
    }),
    ...mapGetters({
      proceededWithoutVideo: 'user/getVideoPermission',
    }),
  },

  watch: {},

  methods: {
    endCallRequest,
  },

  components: {
    LottieAnimation,
  },
  mounted() {
    this.checkReconnect()

    Promise.all(this.getMediaTracks())
      .then(tracks => {
        if (!this.localVideoTrack) {
          this.$store.commit('twilio/SET_LOCAL_VIDEO_TRACK', tracks[0])
        }
        if (!this.localAudioTrack) {
          this.$store.commit('twilio/SET_LOCAL_AUDIO_TRACK', tracks[1])
        }

        if (!this.localDataTrack && !this.proceededWithoutVideo) {
          this.$store.commit('twilio/SET_LOCAL_DATA_TRACK', new LocalDataTrack())
        }
      })
      .then(() => {
        this.localDataTrack.send(JSON.stringify({ messageType: 'help-requested' }))
        this.$modal.close('ConnectToAgent')
        this.$emit('helpRequested')
      })
      .catch(error => {
        this.$app.showCallConnecting = false
        this.$modal.open('UpdateBrowserPermissions')
      })
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function endCallRequest() {
  const dateNow = Date.now()
  const eventTitle = 'HelpRequestCancelled'
  const eventProperties = {
    segment: this.step,
    kit_type: this.$store.getters['user/getKitType'],
    medplum_id: this.$store.getters['user/getMedplumOrderId'],
    barcode: this.$store.getters['user/getBarcodeId'],
    sku: this.$store.getters['user/getSku'],
    customer: this.$store.getters['user/getConfigKeys']
      ? this.$store.getters['user/getConfigKeys'].carrier
      : null,
    waiting_time: dateNow - this.$app.callTimestamp,
    segment_time: dateNow - this.stepTimeStamp,
    start_date: this.$app.callDate.toISOString(),
    end_date: new Date().toISOString(),
    source: this.$route.query.src,
  }

  AmplitudeAPI.logEvent(eventTitle, {
    ...eventProperties,
    linked_amplitude_id: this.$route.query.q,
  })

  MixpanelAPI.track(eventTitle, {
    ...eventProperties,
    application: 'Collection App',
  })

  this.localDataTrack.send(JSON.stringify({ messageType: 'request-cancelled' }))
  this.$app.showCallConnecting = false
  this.$app.callTimestamp = null
  this.$app.callDate = null
  if (this.proceededWithoutVideo) {
    this.disableLocalTracks()
  }
}
</script>
